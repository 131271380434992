import axios from "axios";
import { toast } from "react-toastify";

const token = localStorage.getItem('token');

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";

export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

export const GET_ONE_POST_REQUEST = "GET_ONE_POST_REQUEST";
export const GET_ONE_POST_SUCCESS = "GET_ONE_POST_SUCCESS";
export const GET_ONE_POST_FAILURE = "GET_ONE_POST_FAILURE";

export const createPost = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_POST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/news/posts/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_POST_SUCCESS, payload: res.data });
            navigate('/post');
            toast("Post Created Successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_POST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const getPosts = (status) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_POSTS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/news/posts/v1?status=${status}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_POSTS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_POSTS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deletePost = (id, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_POST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/news/posts/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_POST_SUCCESS, payload: res.data });
            navigate('/post');
            toast("Post Deleted...");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_POST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const updatePost = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_POST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/news/posts/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_POST_SUCCESS, payload: res.data });
            navigate('/post');
            toast("Post Updated Successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_POST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const getOnePost = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_POST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/news/post/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_POST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_POST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};