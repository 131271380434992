import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_ADMIN_LIST_REQUEST = 'GET_ADMIN_LIST_REQUEST';
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS';
export const GET_ADMIN_LIST_FAILURE = 'GET_ADMIN_LIST_FAILURE';

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';

export const getAdminList = (search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ADMIN_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/get/admin/list/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ADMIN_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ADMIN_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteAdmin = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CHANGE_ADMIN_STATE" });
            dispatch({ type: DELETE_ADMIN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/delete/admin/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_ADMIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_ADMIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createAdmin = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CHANGE_ADMIN_STATE" });
            dispatch({ type: CREATE_ADMIN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/create/admin/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_ADMIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_ADMIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};