import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_MY_PROFILE_REQUEST = 'GET_MY_PROFILE_REQUEST';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const GET_MY_PROFILE_FAILURE = 'GET_MY_PROFILE_FAILURE';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE';

export const VERIFY_USER_DOC_REQUEST = 'VERIFY_USER_DOC_REQUEST';
export const VERIFY_USER_DOC_SUCCESS = 'VERIFY_USER_DOC_SUCCESS';
export const VERIFY_USER_DOC_FAILURE = 'VERIFY_USER_DOC_FAILURE';

export const NOT_APPROVED_USER_LIST_REQUEST = 'NOT_APPROVED_USER_LIST_REQUEST';
export const NOT_APPROVED_USER_LIST_SUCCESS = 'NOT_APPROVED_USER_LIST_SUCCESS';
export const NOT_APPROVED_USER_LIST_FAILURE = 'NOT_APPROVED_USER_LIST_FAILURE';

export const CHANGE_APPROVED_USER_STATUS_REQUEST = 'CHANGE_APPROVED_USER_STATUS_REQUEST';
export const CHANGE_APPROVED_USER_STATUS_SUCCESS = 'CHANGE_APPROVED_USER_STATUS_SUCCESS';
export const CHANGE_APPROVED_USER_STATUS_FAILURE = 'CHANGE_APPROVED_USER_STATUS_FAILURE';

export const CHANGE_USER_DELETE_STATUS_REQUEST = 'CHANGE_USER_DELETE_STATUS_REQUEST';
export const CHANGE_USER_DELETE_STATUS_SUCCESS = 'CHANGE_USER_DELETE_STATUS_SUCCESS';
export const CHANGE_USER_DELETE_STATUS_FAILURE = 'CHANGE_USER_DELETE_STATUS_FAILURE';

export const CHANGE_USER_HIDE_STATUS_REQUEST = 'CHANGE_USER_HIDE_STATUS_REQUEST';
export const CHANGE_USER_HIDE_STATUS_SUCCESS = 'CHANGE_USER_HIDE_STATUS_SUCCESS';
export const CHANGE_USER_HIDE_STATUS_FAILURE = 'CHANGE_USER_HIDE_STATUS_FAILURE';

export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS_REQUEST';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE = 'UPDATE_USER_DETAILS_FAILURE';

export const GET_USERS_COUNT_REQUEST = 'GET_USERS_COUNT_REQUEST';
export const GET_USERS_COUNT_SUCCESS = 'GET_USERS_COUNT_SUCCESS';
export const GET_USERS_COUNT_FAILURE = 'GET_USERS_COUNT_FAILURE';

export const CONTACT_LIST_REQUEST = 'CONTACT_LIST_REQUEST';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_FAILURE = 'CONTACT_LIST_FAILURE';

export const CONTACT_DETAILS_REQUEST = 'CONTACT_DETAILS_REQUEST';
export const CONTACT_DETAILS_SUCCESS = 'CONTACT_DETAILS_SUCCESS';
export const CONTACT_DETAILS_FAILURE = 'CONTACT_DETAILS_FAILURE';

export const REPORTED_USERS_LIST_REQUEST = 'REPORTED_USERS_LIST_REQUEST';
export const REPORTED_USERS_LIST_SUCCESS = 'REPORTED_USERS_LIST_SUCCESS';
export const REPORTED_USERS_LIST_FAILURE = 'REPORTED_USERS_LIST_FAILURE';

export const REPORTED_USER_DETAILS_REQUEST = 'REPORTED_USER_DETAILS_REQUEST';
export const REPORTED_USER_DETAILS_SUCCESS = 'REPORTED_USER_DETAILS_SUCCESS';
export const REPORTED_USER_DETAILS_FAILURE = 'REPORTED_USER_DETAILS_FAILURE';

export const loginUser = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_USER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/login/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
            navigate('/');
            toast('Login Successfully');
        } catch (error) {
            console.error(error);
            dispatch({ type: LOGIN_USER_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getMyProfile = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_MY_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/my/details/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_MY_PROFILE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_MY_PROFILE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserList = (search, list, loading) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_LIST_REQUEST, payload: loading });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/list/v1?search=${search}&list=${list}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_USER_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUserDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/details/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_PROFILE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const changeUserStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_CHANGE_USER_STATUS" });
            dispatch({ type: CHANGE_USER_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/change/user/status/${id}/v1`;
            const res = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_USER_STATUS_SUCCESS, payload: res.data });
            toast("User status updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_USER_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const verifyUserDoc = (id, verify, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: VERIFY_USER_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/verify/user/doc/${id}/v1`;
            const res = await axios.put(url, { verify }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: VERIFY_USER_DOC_SUCCESS, payload: res.data });
            navigate('/user');
            toast(`User ${verify === "A" ? "Verified" : "Rejected"} Successfully.`);
        } catch (error) {
            console.error(error);
            dispatch({ type: VERIFY_USER_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const notApprovedUserList = (search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: NOT_APPROVED_USER_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/not/approved/user/list/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: NOT_APPROVED_USER_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: NOT_APPROVED_USER_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const changeUserApprovedStatus = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_APPROVED_USER_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/approved/reject/user/status/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_APPROVED_USER_STATUS_SUCCESS, payload: res.data });
            navigate("/new/user");
            toast("User Approval Status Updated Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_APPROVED_USER_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};

export const changeUserDeleteStatus = (id, deleted) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_CHANGE_USER_STATUS" });
            dispatch({ type: CHANGE_USER_DELETE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/change/user/delete/status/${id}/v1`;
            const res = await axios.put(url, { deleted }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_USER_DELETE_STATUS_SUCCESS, payload: res.data });
            toast("User status updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_USER_DELETE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeUserHideStatus = (id, hide) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_CHANGE_USER_STATUS" });
            dispatch({ type: CHANGE_USER_HIDE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/change/user/hide/status/${id}/v1`;
            const res = await axios.put(url, { hide }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_USER_HIDE_STATUS_SUCCESS, payload: res.data });
            toast("User status updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_USER_HIDE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateUserDetails = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_USER_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/user/details/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: res.data });
            toast("User status updated successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_USER_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getUsersCount = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USERS_COUNT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/users/count/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_USERS_COUNT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USERS_COUNT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getContactList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CONTACT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/list/contact/us/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CONTACT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CONTACT_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getContactDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CONTACT_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/contact/us/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CONTACT_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getReportedUsersList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: REPORTED_USERS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/list/reported/user/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: REPORTED_USERS_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: REPORTED_USERS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getReportedUserDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: REPORTED_USER_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/list/reported/user/details/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: REPORTED_USER_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: REPORTED_USER_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};