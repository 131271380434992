import * as settingActions from "./setting.actions";

export const settingFeatureKey = 'settingInfo';

const initialState = {
    loading: false,
    versions: {},
    errorMsg: "",
    isUpdateVersion: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get os versions
        case settingActions.GET_VERSIONS_REQUEST:
            return {
                ...state,
                loading: false
            }

        case settingActions.GET_VERSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                versions: payload.data
            }

        case settingActions.GET_VERSIONS_FAILURE:
            return {
                ...state,
                loading: false,
                versions: {},
                errorMsg: payload
            }

        // update os versions
        case settingActions.UPDATE_VERSIONS_REQUEST:
            return {
                ...state,
            }

        case settingActions.UPDATE_VERSIONS_SUCCESS:
            return {
                ...state,
                isUpdateVersion: true
            }

        case settingActions.UPDATE_VERSIONS_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isUpdateVersion: false
            }

        // isUpdate version
        case "CLEAR_UPDATE_VERSION":
            return {
                ...state,
                isUpdateVersion: false
            }

        default: return state;
    }
};