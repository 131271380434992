import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_BANK_DETAILS_REQUEST = 'GET_BANK_DETAILS_REQUEST';
export const GET_BANK_DETAILS_SUCCESS = 'GET_BANK_DETAILS_SUCCESS';
export const GET_BANK_DETAILS_FAILURE = 'GET_BANK_DETAILS_FAILURE';

export const UPDATE_BANK_DETAILS_REQUEST = 'UPDATE_BANK_DETAILS_REQUEST';
export const UPDATE_BANK_DETAILS_SUCCESS = 'UPDATE_BANK_DETAILS_SUCCESS';
export const UPDATE_BANK_DETAILS_FAILURE = 'UPDATE_BANK_DETAILS_FAILURE';

export const getBankDetails = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_BANK_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/bank/details/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_BANK_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_BANK_DETAILS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateBankDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_BANK_DETAILS" });
            dispatch({ type: UPDATE_BANK_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/bank/details/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_BANK_DETAILS_SUCCESS, payload: res.data });
            toast("Bank Details Updated Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_BANK_DETAILS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};