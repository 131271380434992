import * as bannerActions from "./banner.actions";

export const bannerFeatureKey = "bannerInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    banners: [],
    sortNumber: [],
    isChangeBannerStatus: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // create banner
        case bannerActions.CREATE_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bannerActions.CREATE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeBannerStatus: true
            }

        case bannerActions.CREATE_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // edit banner
        case bannerActions.EDIT_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bannerActions.EDIT_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeBannerStatus: true
            }

        case bannerActions.EDIT_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete banner
        case bannerActions.DELETE_BANNER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bannerActions.DELETE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeBannerStatus: true
            }

        case bannerActions.DELETE_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get banner list
        case bannerActions.GET_BANNER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bannerActions.GET_BANNER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: payload.data,
                sortNumber: payload.sortNumber
            }

        case bannerActions.GET_BANNER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "IS_CHANGE_BANNER_STATUS":
            return {
                ...state,
                isChangeBannerStatus: false
            }

        default: return state;
    }
};