import * as postActions from './post.actions';

export const postFeatureKey = "postInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    posts: [],
    post: {}
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // create post
        case postActions.CREATE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case postActions.CREATE_POST_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case postActions.CREATE_POST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get Post list
        case postActions.GET_POSTS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case postActions.GET_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                posts: payload.data
            }

        case postActions.GET_POSTS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete post
        case postActions.DELETE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case postActions.DELETE_POST_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case postActions.DELETE_POST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update post
        case postActions.UPDATE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case postActions.UPDATE_POST_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case postActions.UPDATE_POST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get post
        case postActions.GET_ONE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case postActions.GET_ONE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                post: payload.data
            }

        case postActions.GET_ONE_POST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};