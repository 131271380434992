import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import { Provider } from "react-redux";
import { createBrowserHistory } from 'history'
import { store } from "./redux/store";
import Routes from './routes/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const history = createBrowserHistory()
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <BrowserRouter history={history}>
        <ToastContainer />
        <Routes />
      </BrowserRouter>
    </Suspense>
  </Provider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
