import { combineReducers } from 'redux';
import * as userReducers from './user/user.reducers';
import * as dropdownReducers from './dropdowns/dropdown.reducers';
import * as settingReducers from './setting/setting.reducers';
import * as planReducers from './plan/plan.reducers';
import * as postReducers from './post/post.reducers';
import * as bankDetailReducers from './bankDetails/bankDetails.reducers';
import * as adminReducers from './admin/admin.reducers';
import * as paymentReducers from "./payment/payment.reducers";
import * as bannerReducers from "./banner/banner.reducers";
import * as notificationReducers from "./notification/notification.reducer";
import * as phonepePaymentReducers from "./phonepePayment/payment.reducers";
import * as phonepePlanReducers from "./phonepePlan/phonePePla.reducers";
import * as chatReducers from "./chat/chat.reducers";

export const rootReducer = combineReducers({
    [userReducers.userFeatureKey]: userReducers.reducer,
    [dropdownReducers.dropdownFeatureKey]: dropdownReducers.reducer,
    [settingReducers.settingFeatureKey]: settingReducers.reducer,
    [planReducers.planFeatureKey]: planReducers.reducer,
    [postReducers.postFeatureKey]: postReducers.reducer,
    [bankDetailReducers.bankDetailsFeatureKey]: bankDetailReducers.reducer,
    [adminReducers.adminFeatureKey]: adminReducers.reducer,
    [paymentReducers.paymentFeatureKey]: paymentReducers.reducer,
    [bannerReducers.bannerFeatureKey]: bannerReducers.reducer,
    [notificationReducers.notificationFeatureKey]: notificationReducers.reducer,
    [phonepePaymentReducers.phonepePaymentFeatureKey]: phonepePaymentReducers.reducer,
    [phonepePlanReducers.planFeatureKey]: phonepePlanReducers.reducer,
    [chatReducers.chatFeatureKey]: chatReducers.reducer
});