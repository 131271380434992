import * as paymentActions from "./payment.actions";

export const paymentFeatureKey = "paymentInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    paymentList: [],
    paymentDocs: {},
    paymentHistoryList: [],
    paymentHistoryLoading: false,
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // payment list
        case paymentActions.GET_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case paymentActions.GET_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentList: payload.data
            }

        case paymentActions.GET_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get payment verification doc
        case paymentActions.GET_VERIFY_PAYMENT_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.GET_VERIFY_PAYMENT_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentDocs: payload.data
            }

        case paymentActions.GET_VERIFY_PAYMENT_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // payment verification
        case paymentActions.VERIFY_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.VERIFY_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case paymentActions.VERIFY_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        // payment history list
        case paymentActions.GET_PAYMENT_HISTORY_REQUEST:
            return {
                ...state,
                paymentHistoryLoading: false
            }

        case paymentActions.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                paymentHistoryLoading: false,
                paymentHistoryList: payload.data
            }

        case paymentActions.GET_PAYMENT_HISTORY_FAILURE:
            return {
                ...state,
                paymentHistoryLoading: false,
                errorMsg: payload
            }

        default: return state;
    }
};