import * as adminActions from './admin.actions';

export const adminFeatureKey = "adminInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    admins: [],
    isChangeAdminState: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get admin list
        case adminActions.GET_ADMIN_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case adminActions.GET_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                admins: payload.data
            }

        case adminActions.GET_ADMIN_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // delete admin list
        case adminActions.DELETE_ADMIN_REQUEST:
            return {
                ...state,
                loading: false
            }

        case adminActions.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeAdminState: true
            }

        case adminActions.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // create admin list
        case adminActions.CREATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: false
            }

        case adminActions.CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeAdminState: true
            }

        case adminActions.CREATE_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CHANGE_ADMIN_STATE":
            return {
                ...state,
                isChangeAdminState: false
            }

        default: return state;
    }
};