import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_PROFILE_FOR_LIST_REQUEST = 'GET_PROFILE_FOR_LIST_REQUEST';
export const GET_PROFILE_FOR_LIST_SUCCESS = 'GET_PROFILE_FOR_LIST_SUCCESS';
export const GET_PROFILE_FOR_LIST_FAILURE = 'GET_PROFILE_FOR_LIST_FAILURE';

export const GET_MARITAL_LIST_REQUEST = 'GET_MARITAL_LIST_REQUEST';
export const GET_MARITAL_LIST_SUCCESS = 'GET_MARITAL_LIST_SUCCESS';
export const GET_MARITAL_LIST_FAILURE = 'GET_MARITAL_LIST_FAILURE';

export const GET_DEGREE_LIST_REQUEST = 'GET_DEGREE_LIST_REQUEST';
export const GET_DEGREE_LIST_SUCCESS = 'GET_DEGREE_LIST_SUCCESS';
export const GET_DEGREE_LIST_FAILURE = 'GET_DEGREE_LIST_FAILURE';

export const GET_PROFESSION_LIST_REQUEST = 'GET_PROFESSION_LIST_REQUEST';
export const GET_PROFESSION_LIST_SUCCESS = 'GET_PROFESSION_LIST_SUCCESS';
export const GET_PROFESSION_LIST_FAILURE = 'GET_PROFESSION_LIST_FAILURE';

export const GET_SALARY_LIST_REQUEST = 'GET_SALARY_LIST_REQUEST';
export const GET_SALARY_LIST_SUCCESS = 'GET_SALARY_LIST_SUCCESS';
export const GET_SALARY_LIST_FAILURE = 'GET_SALARY_LIST_FAILURE';

export const GET_CASTE_LIST_REQUEST = 'GET_CASTE_LIST_REQUEST';
export const GET_CASTE_LIST_SUCCESS = 'GET_CASTE_LIST_SUCCESS';
export const GET_CASTE_LIST_FAILURE = 'GET_CASTE_LIST_FAILURE';

export const GET_SUB_CASTE_LIST_REQUEST = 'GET_SUB_CASTE_LIST_REQUEST';
export const GET_SUB_CASTE_LIST_SUCCESS = 'GET_SUB_CASTE_LIST_SUCCESS';
export const GET_SUB_CASTE_LIST_FAILURE = 'GET_SUB_CASTE_LIST_FAILURE';

export const GET_COMMUNITY_LIST_REQUEST = 'GET_COMMUNITY_LIST_REQUEST';
export const GET_COMMUNITY_LIST_SUCCESS = 'GET_COMMUNITY_LIST_SUCCESS';
export const GET_COMMUNITY_LIST_FAILURE = 'GET_COMMUNITY_LIST_FAILURE';

export const GET_RELIGION_LIST_REQUEST = 'GET_RELIGION_LIST_REQUEST';
export const GET_RELIGION_LIST_SUCCESS = 'GET_RELIGION_LIST_SUCCESS';
export const GET_RELIGION_LIST_FAILURE = 'GET_RELIGION_LIST_FAILURE';

export const CREATE_PROFILE_FOR_REQUEST = 'CREATE_PROFILE_FOR_REQUEST';
export const CREATE_PROFILE_FOR_SUCCESS = 'CREATE_PROFILE_FOR_SUCCESS';
export const CREATE_PROFILE_FOR_FAILURE = 'CREATE_PROFILE_FOR_FAILURE';

export const CREATE_MARITAL_STATUS_REQUEST = 'CREATE_MARITAL_STATUS_REQUEST';
export const CREATE_MARITAL_STATUS_SUCCESS = 'CREATE_MARITAL_STATUS_SUCCESS';
export const CREATE_MARITAL_STATUS_FAILURE = 'CREATE_MARITAL_STATUS_FAILURE';

export const CREATE_DEGREE_REQUEST = 'CREATE_DEGREE_REQUEST';
export const CREATE_DEGREE_SUCCESS = 'CREATE_DEGREE_SUCCESS';
export const CREATE_DEGREE_FAILURE = 'CREATE_DEGREE_FAILURE';

export const CREATE_PROFESSION_REQUEST = 'CREATE_PROFESSION_REQUEST';
export const CREATE_PROFESSION_SUCCESS = 'CREATE_PROFESSION_SUCCESS';
export const CREATE_PROFESSION_FAILURE = 'CREATE_PROFESSION_FAILURE';

export const CREATE_SALARY_REQUEST = 'CREATE_SALARY_REQUEST';
export const CREATE_SALARY_SUCCESS = 'CREATE_SALARY_SUCCESS';
export const CREATE_SALARY_FAILURE = 'CREATE_SALARY_FAILURE';

export const CREATE_CASTE_REQUEST = 'CREATE_CASTE_REQUEST';
export const CREATE_CASTE_SUCCESS = 'CREATE_CASTE_SUCCESS';
export const CREATE_CASTE_FAILURE = 'CREATE_CASTE_FAILURE';

export const CREATE_SUB_CASTE_REQUEST = 'CREATE_SUB_CASTE_REQUEST';
export const CREATE_SUB_CASTE_SUCCESS = 'CREATE_SUB_CASTE_SUCCESS';
export const CREATE_SUB_CASTE_FAILURE = 'CREATE_SUB_CASTE_FAILURE';

export const CREATE_COMMUNITY_REQUEST = 'CREATE_COMMUNITY_REQUEST';
export const CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS';
export const CREATE_COMMUNITY_FAILURE = 'CREATE_COMMUNITY_FAILURE';

export const CREATE_RELIGION_REQUEST = 'CREATE_RELIGION_REQUEST';
export const CREATE_RELIGION_SUCCESS = 'CREATE_RELIGION_SUCCESS';
export const CREATE_RELIGION_FAILURE = 'CREATE_RELIGION_FAILURE';

export const UPDATE_PROFILE_FOR_REQUEST = 'UPDATE_PROFILE_FOR_REQUEST';
export const UPDATE_PROFILE_FOR_SUCCESS = 'UPDATE_PROFILE_FOR_SUCCESS';
export const UPDATE_PROFILE_FOR_FAILURE = 'UPDATE_PROFILE_FOR_FAILURE';

export const UPDATE_MARITAL_STATUS_REQUEST = 'UPDATE_MARITAL_STATUS_REQUEST';
export const UPDATE_MARITAL_STATUS_SUCCESS = 'UPDATE_MARITAL_STATUS_SUCCESS';
export const UPDATE_MARITAL_STATUS_FAILURE = 'UPDATE_MARITAL_STATUS_FAILURE';

export const UPDATE_DEGREE_REQUEST = 'UPDATE_DEGREE_REQUEST';
export const UPDATE_DEGREE_SUCCESS = 'UPDATE_DEGREE_SUCCESS';
export const UPDATE_DEGREE_FAILURE = 'UPDATE_DEGREE_FAILURE';

export const UPDATE_PROFESSION_REQUEST = 'UPDATE_PROFESSION_REQUEST';
export const UPDATE_PROFESSION_SUCCESS = 'UPDATE_PROFESSION_SUCCESS';
export const UPDATE_PROFESSION_FAILURE = 'UPDATE_PROFESSION_FAILURE';

export const UPDATE_SALARY_REQUEST = 'UPDATE_SALARY_REQUEST';
export const UPDATE_SALARY_SUCCESS = 'UPDATE_SALARY_SUCCESS';
export const UPDATE_SALARY_FAILURE = 'UPDATE_SALARY_FAILURE';

export const UPDATE_CASTE_REQUEST = 'UPDATE_CASTE_REQUEST';
export const UPDATE_CASTE_SUCCESS = 'UPDATE_CASTE_SUCCESS';
export const UPDATE_CASTE_FAILURE = 'UPDATE_CASTE_FAILURE';

export const UPDATE_SUB_CASTE_REQUEST = 'UPDATE_SUB_CASTE_REQUEST';
export const UPDATE_SUB_CASTE_SUCCESS = 'UPDATE_SUB_CASTE_SUCCESS';
export const UPDATE_SUB_CASTE_FAILURE = 'UPDATE_SUB_CASTE_FAILURE';

export const UPDATE_COMMUNITY_REQUEST = 'UPDATE_COMMUNITY_REQUEST';
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_FAILURE = 'UPDATE_COMMUNITY_FAILURE';

export const UPDATE_RELIGION_REQUEST = 'UPDATE_RELIGION_REQUEST';
export const UPDATE_RELIGION_SUCCESS = 'UPDATE_RELIGION_SUCCESS';
export const UPDATE_RELIGION_FAILURE = 'UPDATE_RELIGION_FAILURE';

export const DELETE_PROFILE_FOR_REQUEST = 'DELETE_PROFILE_FOR_REQUEST';
export const DELETE_PROFILE_FOR_SUCCESS = 'DELETE_PROFILE_FOR_SUCCESS';
export const DELETE_PROFILE_FOR_FAILURE = 'DELETE_PROFILE_FOR_FAILURE';

export const DELETE_MARITAL_STATUS_REQUEST = 'DELETE_MARITAL_STATUS_REQUEST';
export const DELETE_MARITAL_STATUS_SUCCESS = 'DELETE_MARITAL_STATUS_SUCCESS';
export const DELETE_MARITAL_STATUS_FAILURE = 'DELETE_MARITAL_STATUS_FAILURE';

export const DELETE_DEGREE_REQUEST = 'DELETE_DEGREE_REQUEST';
export const DELETE_DEGREE_SUCCESS = 'DELETE_DEGREE_SUCCESS';
export const DELETE_DEGREE_FAILURE = 'DELETE_DEGREE_FAILURE';

export const DELETE_PROFESSION_REQUEST = 'DELETE_PROFESSION_REQUEST';
export const DELETE_PROFESSION_SUCCESS = 'DELETE_PROFESSION_SUCCESS';
export const DELETE_PROFESSION_FAILURE = 'DELETE_PROFESSION_FAILURE';

export const DELETE_SALARY_REQUEST = 'DELETE_SALARY_REQUEST';
export const DELETE_SALARY_SUCCESS = 'DELETE_SALARY_SUCCESS';
export const DELETE_SALARY_FAILURE = 'DELETE_SALARY_FAILURE';

export const DELETE_CASTE_REQUEST = 'DELETE_CASTE_REQUEST';
export const DELETE_CASTE_SUCCESS = 'DELETE_CASTE_SUCCESS';
export const DELETE_CASTE_FAILURE = 'DELETE_CASTE_FAILURE';

export const DELETE_SUB_CASTE_REQUEST = 'DELETE_SUB_CASTE_REQUEST';
export const DELETE_SUB_CASTE_SUCCESS = 'DELETE_SUB_CASTE_SUCCESS';
export const DELETE_SUB_CASTE_FAILURE = 'DELETE_SUB_CASTE_FAILURE';

export const DELETE_COMMUNITY_REQUEST = 'DELETE_COMMUNITY_REQUEST';
export const DELETE_COMMUNITY_SUCCESS = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_FAILURE = 'DELETE_COMMUNITY_FAILURE';

export const DELETE_RELIGION_REQUEST = 'DELETE_RELIGION_REQUEST';
export const DELETE_RELIGION_SUCCESS = 'DELETE_RELIGION_SUCCESS';
export const DELETE_RELIGION_FAILURE = 'DELETE_RELIGION_FAILURE';

export const getProfileForList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PROFILE_FOR_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/profile/for/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PROFILE_FOR_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PROFILE_FOR_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getMaritalList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_MARITAL_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/marital/status/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_MARITAL_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_MARITAL_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getDegreeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_DEGREE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/degree/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_DEGREE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DEGREE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getProfessionList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PROFESSION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/profession/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PROFESSION_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PROFESSION_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getSalaryList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SALARY_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/salary/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_SALARY_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SALARY_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getCasteList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CASTE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/caste/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_CASTE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CASTE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getSubCasteList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SUB_CASTE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/sub/caste/list/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_SUB_CASTE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SUB_CASTE_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getCommunityList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COMMUNITY_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/community/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_COMMUNITY_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COMMUNITY_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getReligionList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_RELIGION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/religion/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_RELIGION_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_RELIGION_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createProfileFor = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFILE_FOR' });
            dispatch({ type: CREATE_PROFILE_FOR_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/profile/for/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_PROFILE_FOR_SUCCESS, payload: res.data });
            toast("Profile For Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PROFILE_FOR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createMaritalStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_MARITAL' });
            dispatch({ type: CREATE_MARITAL_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/marital/status/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_MARITAL_STATUS_SUCCESS, payload: res.data });
            toast("Marital Status Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_MARITAL_STATUS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createDegree = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_DEGREE' });
            dispatch({ type: CREATE_DEGREE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/degree/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_DEGREE_SUCCESS, payload: res.data });
            toast("Degree Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_DEGREE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createProfession = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFESSION' });
            dispatch({ type: CREATE_PROFESSION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/profession/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_PROFESSION_SUCCESS, payload: res.data });
            toast("Profession Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PROFESSION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createSalary = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SALARY' });
            dispatch({ type: CREATE_SALARY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/salary/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_SALARY_SUCCESS, payload: res.data });
            toast("Salary Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_SALARY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createCaste = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CASTE' });
            dispatch({ type: CREATE_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/caste/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_CASTE_SUCCESS, payload: res.data });
            toast("Caste Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createSubCaste = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SUB_CASTE' });
            dispatch({ type: CREATE_SUB_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/sub/caste/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_SUB_CASTE_SUCCESS, payload: res.data });
            toast("SubCaste Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_SUB_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createCommunity = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_COMMUNITY' });
            dispatch({ type: CREATE_COMMUNITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/community/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_COMMUNITY_SUCCESS, payload: res.data });
            toast("Community Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_COMMUNITY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const createreligion = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_RELIIGION' });
            dispatch({ type: CREATE_RELIGION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/religion/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_RELIGION_SUCCESS, payload: res.data });
            toast("Religion Created");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_RELIGION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateProfileFor = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFILE_FOR' });
            dispatch({ type: UPDATE_PROFILE_FOR_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/profile/for/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_PROFILE_FOR_SUCCESS, payload: res.data });
            toast("Profile For Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PROFILE_FOR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateMaritalStatus = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_MARITAL' });
            dispatch({ type: UPDATE_MARITAL_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/marital/status/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_MARITAL_STATUS_SUCCESS, payload: res.data });
            toast("Marital Status Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_MARITAL_STATUS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateDegree = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_DEGREE' });
            dispatch({ type: UPDATE_DEGREE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/degree/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_DEGREE_SUCCESS, payload: res.data });
            toast("Degree Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_DEGREE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateProfession = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFESSION' });
            dispatch({ type: UPDATE_PROFESSION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/profession/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_PROFESSION_SUCCESS, payload: res.data });
            toast("Profession Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PROFESSION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateSalary = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SALARY' });
            dispatch({ type: UPDATE_SALARY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/salary/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_SALARY_SUCCESS, payload: res.data });
            toast("Salary Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SALARY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateCaste = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CASTE' });
            dispatch({ type: UPDATE_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/caste/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_CASTE_SUCCESS, payload: res.data });
            toast("Caste Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateSubCaste = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SUB_CASTE' });
            dispatch({ type: UPDATE_SUB_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/sub/caste/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_SUB_CASTE_SUCCESS, payload: res.data });
            toast("SubCaste Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SUB_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateCommunity = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_COMMUNITY' });
            dispatch({ type: UPDATE_COMMUNITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/community/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_COMMUNITY_SUCCESS, payload: res.data });
            toast("Community Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_COMMUNITY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const updateReligion = (id, type) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_RELIGION' });
            dispatch({ type: UPDATE_RELIGION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/religion/${id}/v1`;
            const res = await axios.put(url, { type }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_RELIGION_SUCCESS, payload: res.data });
            toast("Religion Updated");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_RELIGION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteProfileFor = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFILE_FOR' });
            dispatch({ type: DELETE_PROFILE_FOR_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/profile/for/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_PROFILE_FOR_SUCCESS, payload: res.data });
            toast("Profile For Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_PROFILE_FOR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteMaritalStatus = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_MARITAL' });
            dispatch({ type: DELETE_MARITAL_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/marital/status/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_MARITAL_STATUS_SUCCESS, payload: res.data });
            toast("Marital Status Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_MARITAL_STATUS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteDegree = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_DEGREE' });
            dispatch({ type: DELETE_DEGREE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/degree/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_DEGREE_SUCCESS, payload: res.data });
            toast("Degree Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_DEGREE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteProfession = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_PROFESSION' });
            dispatch({ type: DELETE_PROFESSION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/profession/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_PROFESSION_SUCCESS, payload: res.data });
            toast("Profession Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_PROFESSION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteSalary = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SALARY' });
            dispatch({ type: DELETE_SALARY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/salary/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_SALARY_SUCCESS, payload: res.data });
            toast("Salary Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_SALARY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteCaste = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_CASTE' });
            dispatch({ type: DELETE_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/caste/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_CASTE_SUCCESS, payload: res.data });
            toast("Caste Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteSubCaste = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_SUB_CASTE' });
            dispatch({ type: DELETE_SUB_CASTE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/sub/caste/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_SUB_CASTE_SUCCESS, payload: res.data });
            toast("SubCaste Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_SUB_CASTE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteCommunity = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_COMMUNITY' });
            dispatch({ type: DELETE_COMMUNITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/community/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_COMMUNITY_SUCCESS, payload: res.data });
            toast("Community Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_COMMUNITY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteReligion = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CLEAR_CREATE_RELIGION' });
            dispatch({ type: DELETE_RELIGION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/religion/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_RELIGION_SUCCESS, payload: res.data });
            toast("Religion Deleted");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_RELIGION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};