import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';

export const EDIT_BANNER_REQUEST = 'EDIT_BANNER_REQUEST';
export const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS';
export const EDIT_BANNER_FAILURE = 'EDIT_BANNER_FAILURE';

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE';

export const GET_BANNER_LIST_REQUEST = 'GET_BANNER_LIST_REQUEST';
export const GET_BANNER_LIST_SUCCESS = 'GET_BANNER_LIST_SUCCESS';
export const GET_BANNER_LIST_FAILURE = 'GET_BANNER_LIST_FAILURE';

export const createBanner = (data, bannerToggle, setBanner, setImgFile) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_CHANGE_BANNER_STATUS" });
            dispatch({ type: CREATE_BANNER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/banner/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_BANNER_SUCCESS, payload: res.data });
            setBanner({ startDate: "", endDate: "", image: "", sort: null });
            setImgFile();
            bannerToggle();
            toast("Banner Created Successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_BANNER_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editBanner = (id, data, bannerToggle, setBanner, setImgFile) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_CHANGE_BANNER_STATUS" });
            dispatch({ type: EDIT_BANNER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/edit/banner/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: EDIT_BANNER_SUCCESS, payload: res.data });
            setBanner({ startDate: "", endDate: "", image: "", sort: null });
            setImgFile();
            bannerToggle();
            toast("Banner Updated Successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_BANNER_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteBanner = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_CHANGE_BANNER_STATUS" });
            dispatch({ type: DELETE_BANNER_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/banner/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_BANNER_SUCCESS, payload: res.data });
            toast("Banner Deleted Successfully...");
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_BANNER_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getBannerList = (status) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_BANNER_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/banner/list/v1?status=${status}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_BANNER_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_BANNER_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};