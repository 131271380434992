import * as bankDetailActions from './bankDetails.actions';

export const bankDetailsFeatureKey = "bankDetailsInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    bankDetail: {},
    isUpdateBankDetails: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case bankDetailActions.GET_BANK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bankDetailActions.GET_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                bankDetail: payload.data
            }

        case bankDetailActions.GET_BANK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // update bank details
        case bankDetailActions.UPDATE_BANK_DETAILS_REQUEST:
            return {
                ...state,
                loading: false
            }

        case bankDetailActions.UPDATE_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdateBankDetails: true
            }

        case bankDetailActions.UPDATE_BANK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "UPDATE_BANK_DETAILS":
            return {
                ...state,
                isUpdateBankDetails: false
            }

        default: return state;
    }
};