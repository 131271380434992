import * as paymentActions from "./payment.actions";

export const phonepePaymentFeatureKey = "phonepePaymentInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    paymentList: [],
    paymentDetails: {},
    isAddPayment: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case paymentActions.GET_PHONEPE_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.GET_PHONEPE_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentList: payload.data
            }

        case paymentActions.GET_PHONEPE_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case paymentActions.GET_PHONEPE_PAYMENT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.GET_PHONEPE_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentDetails: payload.data
            }

        case paymentActions.GET_PHONEPE_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case paymentActions.CREATE_PHONEPE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case paymentActions.CREATE_PHONEPE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                isAddPayment: true
            }

        case paymentActions.CREATE_PHONEPE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "IS_ADD_PAYMENT": {
            return {
                ...state,
                isAddPayment: false
            }
        }

        default: return state;
    }
};