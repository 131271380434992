import * as userActions from './user.actions';

export const userFeatureKey = 'userInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    loggedUser: {},
    users: [],
    notApprovedUsers: [],
    counts: {},
    userDetails: {},
    devices: [],
    contacts: [],
    contactDetails: {},
    reportedUsers: [],
    reportedUserDetails: {},
    isChangeUserStatus: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // login user
        case userActions.LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LOGIN_USER_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                loading: false,
                loggedUser: payload.data
            }

        case userActions.LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get my profile
        case userActions.GET_MY_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedUser: payload.data
            }

        case userActions.GET_MY_PROFILE_FAILURE:
            localStorage.removeItem('token');
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user list
        case userActions.GET_USER_LIST_REQUEST:
            return {
                ...state,
                loading: payload
            }

        case userActions.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                users: payload.data,
                loading: false
            }

        case userActions.GET_USER_LIST_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                users: [],
                loading: false
            }

        // get user info
        case userActions.GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetails: payload.data,
                devices: payload.userDevices
            }

        case userActions.GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                userDetails: [],
                devices: []
            }

        // change user status
        case userActions.CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: true
            }

        case userActions.CHANGE_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false,
                errorMsg: payload
            }

        // verify user
        case userActions.VERIFY_USER_DOC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.VERIFY_USER_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case userActions.VERIFY_USER_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // not approved users
        case userActions.NOT_APPROVED_USER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.NOT_APPROVED_USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                notApprovedUsers: payload.data
            }

        case userActions.NOT_APPROVED_USER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // Change user approved Status
        case userActions.CHANGE_APPROVED_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CHANGE_APPROVED_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.CHANGE_APPROVED_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // change user delete status
        case userActions.CHANGE_USER_DELETE_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CHANGE_USER_DELETE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: true
            }

        case userActions.CHANGE_USER_DELETE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false,
                errorMsg: payload
            }

        // change user hide status
        case userActions.CHANGE_USER_HIDE_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CHANGE_USER_HIDE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: true
            }

        case userActions.CHANGE_USER_HIDE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false,
                errorMsg: payload
            }

        // update user details
        case userActions.UPDATE_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.UPDATE_USER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get users count
        case userActions.GET_USERS_COUNT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GET_USERS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                counts: payload.data
            }

        case userActions.GET_USERS_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.CONTACT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CONTACT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: payload.data
            }

        case userActions.CONTACT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.CONTACT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                contactDetails: payload.data
            }

        case userActions.CONTACT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.REPORTED_USERS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.REPORTED_USERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                reportedUsers: payload.data
            }

        case userActions.REPORTED_USERS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.REPORTED_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.REPORTED_USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                reportedUserDetails: payload.data
            }

        case userActions.REPORTED_USER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_CHANGE_USER_STATUS":
            return {
                ...state,
                isChangeUserStatus: false
            }

        default: return state;
    }
};