import React from "react";
import "./loader.scss";
import Lottie from "lottie-react";
import LoadingJSON from "../../lotties/loading.json";

const Loader = () => (
  <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Lottie style={{ width: "200px", height: "200px" }} animationData={LoadingJSON} />
  </div>
);
export default Loader;
