import * as planActions from './plan.actions';

export const planFeatureKey = 'planInfo';

const initialState = {
    loading: true,
    errorMsg: "",
    plans: [],
    trialDays: null,
    isPlanStateChange: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case planActions.GET_PLANS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.GET_PLANS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                plans: payload.data,
                trialDays: payload.trialDays
            }

        case planActions.GET_PLANS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.ADD_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.ADD_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true
            }

        case planActions.ADD_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.DELETE_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.DELETE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true
            }

        case planActions.DELETE_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.UPDATE_PLAN_FAILURE:
            return {
                ...state,
                loading: false
            }

        case planActions.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true
            }

        case planActions.UPDATE_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.UPDATE_TRIAL_DAYS_REQUEST:
            return {
                ...state,
                loading: false
            }

        case planActions.UPDATE_TRIAL_DAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true
            }

        case planActions.UPDATE_TRIAL_DAYS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "PLAN_STATE_CHANGE":
            return {
                ...state,
                isPlanStateChange: false
            }

        default: return state;
    }
};