import * as notificationActions from "./notification.actions";

export const notificationFeatureKey = "notificationInfo";

const initialState = {
    loading: false,
    errorMessage: "",
    notificationList: []
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case notificationActions.SEND_NOTIFICATION_TO_ALL_USERS_REQUEST:
            return {
                ...state,
                loading: false
            }

        case notificationActions.SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: [payload.data, ...state.notificationList]
            }

        case notificationActions.SEND_NOTIFICATION_TO_ALL_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        case notificationActions.GET_ALL_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case notificationActions.GET_ALL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: payload.data
            }

        case notificationActions.GET_ALL_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        case notificationActions.SEND_NOTIFICATION_TO_USER_REQUEST:
            return {
                ...state,
                loading: false
            }

        case notificationActions.SEND_NOTIFICATION_TO_USER_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case notificationActions.SEND_NOTIFICATION_TO_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        default: return state;
    }
};