import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const CREATE_PURCHASE_PLANS_LIST_REQUEST = 'CREATE_PURCHASE_PLANS_LIST_REQUEST';
export const CREATE_PURCHASE_PLANS_LIST_SUCCESS = 'CREATE_PURCHASE_PLANS_LIST_SUCCESS';
export const CREATE_PURCHASE_PLANS_LIST_FAILURE = 'CREATE_PURCHASE_PLANS_LIST_FAILURE';

export const GET_PURCHASE_PLANS_LIST_REQUEST = 'GET_PURCHASE_PLANS_LIST_REQUEST';
export const GET_PURCHASE_PLANS_LIST_SUCCESS = 'GET_PURCHASE_PLANS_LIST_SUCCESS';
export const GET_PURCHASE_PLANS_LIST_FAILURE = 'GET_PURCHASE_PLANS_LIST_FAILURE';

export const UPDATE_PURCHASE_PLANS_LIST_REQUEST = 'UPDATE_PURCHASE_PLANS_LIST_REQUEST';
export const UPDATE_PURCHASE_PLANS_LIST_SUCCESS = 'UPDATE_PURCHASE_PLANS_LIST_SUCCESS';
export const UPDATE_PURCHASE_PLANS_LIST_FAILURE = 'UPDATE_PURCHASE_PLANS_LIST_FAILURE';

export const createPlan = (data, closeModal, clearData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: CREATE_PURCHASE_PLANS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/purchase/plan/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_PURCHASE_PLANS_LIST_SUCCESS, payload: res.data });
            closeModal();
            clearData();
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PURCHASE_PLANS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getPlansList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PURCHASE_PLANS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/purchase/plans/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PURCHASE_PLANS_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PURCHASE_PLANS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updatePlan = (id, data, toggle, clear) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: UPDATE_PURCHASE_PLANS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/purchase/plan/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_PURCHASE_PLANS_LIST_SUCCESS, payload: res.data });
            clear();
            toggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PURCHASE_PLANS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};