import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_PLANS_LIST_REQUEST = 'GET_PLANS_LIST_REQUEST';
export const GET_PLANS_LIST_SUCCESS = 'GET_PLANS_LIST_SUCCESS';
export const GET_PLANS_LIST_FAILURE = 'GET_PLANS_LIST_FAILURE';

export const ADD_PLAN_REQUEST = 'ADD_PLAN_REQUEST';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';
export const ADD_PLAN_FAILURE = 'ADD_PLAN_FAILURE';

export const DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE';

export const UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE';

export const UPDATE_TRIAL_DAYS_REQUEST = 'UPDATE_TRIAL_DAYS_REQUEST';
export const UPDATE_TRIAL_DAYS_SUCCESS = 'UPDATE_TRIAL_DAYS_SUCCESS';
export const UPDATE_TRIAL_DAYS_FAILURE = 'UPDATE_TRIAL_DAYS_FAILURE';

export const getPlansList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PLANS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/plans/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PLANS_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PLANS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const addPlan = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: ADD_PLAN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/plan/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ADD_PLAN_SUCCESS, payload: res.data });
            toast("Plan Added Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_PLAN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deletePlan = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: DELETE_PLAN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/remove/plan/${id}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DELETE_PLAN_SUCCESS, payload: res.data });
            toast('Plan Deleted Successfully');
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_PLAN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updatePlan = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: UPDATE_PLAN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/plan/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_PLAN_SUCCESS, payload: res.data });
            toast("Plan Updated Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PLAN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateTrialDays = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "PLAN_STATE_CHANGE" });
            dispatch({ type: UPDATE_TRIAL_DAYS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/trial/day/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_TRIAL_DAYS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_TRIAL_DAYS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};