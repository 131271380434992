import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_FAILURE = 'CHAT_LIST_FAILURE';

export const CHAT_DETAILS_REQUEST = 'CHAT_DETAILS_REQUEST';
export const CHAT_DETAILS_SUCCESS = 'CHAT_DETAILS_SUCCESS';
export const CHAT_DETAILS_FAILURE = 'CHAT_DETAILS_FAILURE';

export const getChatList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHAT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/chat/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHAT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHAT_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getChatDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHAT_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/chat/details/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHAT_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHAT_DETAILS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};