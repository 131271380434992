import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_PAYMENT_LIST_REQUEST = 'GET_PAYMENT_LIST_REQUEST';
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_FAILURE = 'GET_PAYMENT_LIST_FAILURE';

export const GET_VERIFY_PAYMENT_DOC_REQUEST = 'GET_VERIFY_PAYMENT_DOC_REQUEST';
export const GET_VERIFY_PAYMENT_DOC_SUCCESS = 'GET_VERIFY_PAYMENT_DOC_SUCCESS';
export const GET_VERIFY_PAYMENT_DOC_FAILURE = 'GET_VERIFY_PAYMENT_DOC_FAILURE';

export const VERIFY_PAYMENT_REQUEST = 'VERIFY_PAYMENT_REQUEST';
export const VERIFY_PAYMENT_SUCCESS = 'VERIFY_PAYMENT_SUCCESS';
export const VERIFY_PAYMENT_FAILURE = 'VERIFY_PAYMENT_FAILURE';

export const GET_PAYMENT_HISTORY_REQUEST = 'GET_PAYMENT_HISTORY_REQUEST';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_FAILURE = 'GET_PAYMENT_HISTORY_FAILURE';

export const getPaymentList = (verified) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PAYMENT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/payments/list?verified=${verified}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PAYMENT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PAYMENT_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getPaymentDoc = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_VERIFY_PAYMENT_DOC_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/payments/verify/doc/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_VERIFY_PAYMENT_DOC_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_VERIFY_PAYMENT_DOC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const verifyPayment = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: VERIFY_PAYMENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/verify/payment/${id}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: VERIFY_PAYMENT_SUCCESS, payload: res.data });
            navigate("/payment");
            toast(`${data?.verify === "A" ? "Payment Verified." : "Payment Verification Rejected."}`);
        } catch (error) {
            console.error(error);
            dispatch({ type: VERIFY_PAYMENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getPaymentHistoryList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PAYMENT_HISTORY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/payments/history/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PAYMENT_HISTORY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PAYMENT_HISTORY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};