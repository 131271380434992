import * as dropdownActions from './dropdown.actions';

export const dropdownFeatureKey = 'dropdownInfo';

const initialState = {
    loading: false,
    profileFor: [],
    marital: [],
    degree: [],
    profession: [],
    salary: [],
    caste: [],
    subCaste: [],
    community: [],
    religions: [],
    errorMsg: "",
    isCreateProfileFor: false,
    isCreateMarital: false,
    isCreateDegree: false,
    isCreateProfession: false,
    isCreateSalary: false,
    isCreateCaste: false,
    isCreateSubCaste: false,
    isCreateCommunity: false,
    isCreateReligion: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // create profile for
        case dropdownActions.CREATE_PROFILE_FOR_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_PROFILE_FOR_SUCCESS:
            return {
                ...state,
                isCreateProfileFor: true
            }

        case dropdownActions.CREATE_PROFILE_FOR_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfileFor: false
            }

        // create Marital status
        case dropdownActions.CREATE_MARITAL_STATUS_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_MARITAL_STATUS_SUCCESS:
            return {
                ...state,
                isCreateMarital: true
            }

        case dropdownActions.CREATE_MARITAL_STATUS_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateMarital: false
            }

        // create Degree
        case dropdownActions.CREATE_DEGREE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_DEGREE_SUCCESS:
            return {
                ...state,
                isCreateDegree: true
            }

        case dropdownActions.CREATE_DEGREE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateDegree: false
            }

        // create Profession
        case dropdownActions.CREATE_PROFESSION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_PROFESSION_SUCCESS:
            return {
                ...state,
                isCreateProfession: true
            }

        case dropdownActions.CREATE_PROFESSION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfession: false
            }

        // create Salary
        case dropdownActions.CREATE_SALARY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_SALARY_SUCCESS:
            return {
                ...state,
                isCreateSalary: true
            }

        case dropdownActions.CREATE_SALARY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSalary: false
            }

        // create Caste
        case dropdownActions.CREATE_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_CASTE_SUCCESS:
            return {
                ...state,
                isCreateCaste: true
            }

        case dropdownActions.CREATE_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCaste: false
            }

        // create SubCaste
        case dropdownActions.CREATE_SUB_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_SUB_CASTE_SUCCESS:
            return {
                ...state,
                isCreateSubCaste: true
            }

        case dropdownActions.CREATE_SUB_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSubCaste: false
            }

        // create Community
        case dropdownActions.CREATE_COMMUNITY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_COMMUNITY_SUCCESS:
            return {
                ...state,
                isCreateCommunity: true
            }

        case dropdownActions.CREATE_COMMUNITY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCommunity: false
            }

        // create Religion
        case dropdownActions.CREATE_RELIGION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.CREATE_RELIGION_SUCCESS:
            return {
                ...state,
                isCreateReligion: true
            }

        case dropdownActions.CREATE_RELIGION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateReligion: false
            }

        // get profile for list
        case dropdownActions.GET_PROFILE_FOR_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_PROFILE_FOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profileFor: payload.data
            }

        case dropdownActions.GET_PROFILE_FOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                profileFor: []
            }

        // get marital list
        case dropdownActions.GET_MARITAL_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_MARITAL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                marital: payload.data
            }

        case dropdownActions.GET_MARITAL_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                marital: []
            }

        // get degree list
        case dropdownActions.GET_DEGREE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_DEGREE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                degree: payload.data
            }

        case dropdownActions.GET_DEGREE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                degree: []
            }

        // get profession list
        case dropdownActions.GET_PROFESSION_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_PROFESSION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profession: payload.data
            }

        case dropdownActions.GET_PROFESSION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                profession: []
            }

        // get salary list
        case dropdownActions.GET_SALARY_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_SALARY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                salary: payload.data
            }

        case dropdownActions.GET_SALARY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                salary: []
            }

        // get caste list
        case dropdownActions.GET_CASTE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_CASTE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                caste: payload.data
            }

        case dropdownActions.GET_CASTE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                caste: []
            }

        // get sub-caste list
        case dropdownActions.GET_SUB_CASTE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_SUB_CASTE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                subCaste: payload.data
            }

        case dropdownActions.GET_SUB_CASTE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                subCaste: []
            }

        // get community list
        case dropdownActions.GET_COMMUNITY_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_COMMUNITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                community: payload.data
            }

        case dropdownActions.GET_COMMUNITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                community: []
            }

        // get religion list
        case dropdownActions.GET_RELIGION_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case dropdownActions.GET_RELIGION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                religions: payload.data
            }

        case dropdownActions.GET_RELIGION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                religions: []
            }

        // update profile for
        case dropdownActions.UPDATE_PROFILE_FOR_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_PROFILE_FOR_SUCCESS:
            return {
                ...state,
                isCreateProfileFor: true
            }

        case dropdownActions.UPDATE_PROFILE_FOR_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfileFor: false
            }

        // update Marital status
        case dropdownActions.UPDATE_MARITAL_STATUS_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_MARITAL_STATUS_SUCCESS:
            return {
                ...state,
                isCreateMarital: true
            }

        case dropdownActions.UPDATE_MARITAL_STATUS_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateMarital: false
            }

        // update Degree
        case dropdownActions.UPDATE_DEGREE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_DEGREE_SUCCESS:
            return {
                ...state,
                isCreateDegree: true
            }

        case dropdownActions.UPDATE_DEGREE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateDegree: false
            }

        // update Profession
        case dropdownActions.UPDATE_PROFESSION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_PROFESSION_SUCCESS:
            return {
                ...state,
                isCreateProfession: true
            }

        case dropdownActions.UPDATE_PROFESSION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfession: false
            }

        // update Salary
        case dropdownActions.UPDATE_SALARY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_SALARY_SUCCESS:
            return {
                ...state,
                isCreateSalary: true
            }

        case dropdownActions.UPDATE_SALARY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSalary: false
            }

        // update Caste
        case dropdownActions.UPDATE_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_CASTE_SUCCESS:
            return {
                ...state,
                isCreateCaste: true
            }

        case dropdownActions.UPDATE_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCaste: false
            }

        // update SubCaste
        case dropdownActions.UPDATE_SUB_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_SUB_CASTE_SUCCESS:
            return {
                ...state,
                isCreateSubCaste: true
            }

        case dropdownActions.UPDATE_SUB_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSubCaste: false
            }

        // update Community
        case dropdownActions.UPDATE_COMMUNITY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_COMMUNITY_SUCCESS:
            return {
                ...state,
                isCreateCommunity: true
            }

        case dropdownActions.UPDATE_COMMUNITY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCommunity: false
            }

        // update Community
        case dropdownActions.UPDATE_RELIGION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.UPDATE_RELIGION_SUCCESS:
            return {
                ...state,
                isCreateReligion: true
            }

        case dropdownActions.UPDATE_RELIGION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateReligion: false
            }

        // delete profile for
        case dropdownActions.DELETE_PROFILE_FOR_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_PROFILE_FOR_SUCCESS:
            return {
                ...state,
                isCreateProfileFor: true
            }

        case dropdownActions.DELETE_PROFILE_FOR_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfileFor: false
            }

        // delete Marital status
        case dropdownActions.DELETE_MARITAL_STATUS_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_MARITAL_STATUS_SUCCESS:
            return {
                ...state,
                isCreateMarital: true
            }

        case dropdownActions.DELETE_MARITAL_STATUS_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateMarital: false
            }

        // delete Degree
        case dropdownActions.DELETE_DEGREE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_DEGREE_SUCCESS:
            return {
                ...state,
                isCreateDegree: true
            }

        case dropdownActions.DELETE_DEGREE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateDegree: false
            }

        // delete Profession
        case dropdownActions.DELETE_PROFESSION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_PROFESSION_SUCCESS:
            return {
                ...state,
                isCreateProfession: true
            }

        case dropdownActions.DELETE_PROFESSION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateProfession: false
            }

        // delete Salary
        case dropdownActions.DELETE_SALARY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_SALARY_SUCCESS:
            return {
                ...state,
                isCreateSalary: true
            }

        case dropdownActions.DELETE_SALARY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSalary: false
            }

        // delete Caste
        case dropdownActions.DELETE_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_CASTE_SUCCESS:
            return {
                ...state,
                isCreateCaste: true
            }

        case dropdownActions.DELETE_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCaste: false
            }

        // delete SubCaste
        case dropdownActions.DELETE_SUB_CASTE_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_SUB_CASTE_SUCCESS:
            return {
                ...state,
                isCreateSubCaste: true
            }

        case dropdownActions.DELETE_SUB_CASTE_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateSubCaste: false
            }

        // delete community
        case dropdownActions.DELETE_COMMUNITY_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_COMMUNITY_SUCCESS:
            return {
                ...state,
                isCreateCommunity: true
            }

        case dropdownActions.DELETE_COMMUNITY_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateCommunity: false
            }

        // delete religion
        case dropdownActions.DELETE_RELIGION_REQUEST:
            return {
                ...state,
            }

        case dropdownActions.DELETE_RELIGION_SUCCESS:
            return {
                ...state,
                isCreateReligion: true
            }

        case dropdownActions.DELETE_RELIGION_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                isCreateReligion: false
            }

        // clear is created profile for
        case 'CLEAR_CREATE_PROFILE_FOR':
            return {
                ...state,
                isCreateProfileFor: false
            }

        // clear is created marital
        case 'CLEAR_CREATE_MARITAL':
            return {
                ...state,
                isCreateMarital: false
            }

        // clear is created degree
        case 'CLEAR_CREATE_DEGREE':
            return {
                ...state,
                isCreateDegree: false
            }

        // clear is created profession
        case 'CLEAR_CREATE_PROFESSION':
            return {
                ...state,
                isCreateProfession: false
            }

        // clear is created salary
        case 'CLEAR_CREATE_SALARY':
            return {
                ...state,
                isCreateSalary: false
            }

        // clear is created caste
        case 'CLEAR_CREATE_CASTE':
            return {
                ...state,
                isCreateCaste: false
            }

        // clear is created subCaste
        case 'CLEAR_CREATE_SUB_CASTE':
            return {
                ...state,
                isCreateSubCaste: false
            }

        // clear is created Community
        case 'CLEAR_CREATE_COMMUNITY':
            return {
                ...state,
                isCreateCommunity: false
            }

        default: return state;
    }
}