
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-indent */

import React, { lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom'
import { getMyProfile } from '../redux/user/user.actions.js';
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

const Dashboard = lazy(() => import("../views/dashboard/Dashboard.js"));
const Login = lazy(() => import("../components/auth/Login"));
const NotFound = lazy(() => import("../components/NotFound/index"));
const Dropdown = lazy(() => import("../views/dropdown/Dropdown"));
const Setting = lazy(() => import("../views/setting/Setting"));
const User = lazy(() => import("../views/users/User"));
const NewUser = lazy(() => import("../views/newUsers/NewUsers"));
const NewRegistersUser = lazy(() => import("../views/newRegisterUsers/NewRegsisterUsers"));
const RejectedCandidates = lazy(() => import("../views/rejectedUsers/RejectedUsers"));
const UpdateUserStatus = lazy(() => import("../views/newUsers/UpdateUserStatus"));
const Admin = lazy(() => import("../views/admin/Admin"));
const UserDetails = lazy(() => import("../views/users/UserDetails"));
const UserVerify = lazy(() => import('../views/users/UserVerify'));
const Post = lazy(() => import("../views/post/Post"));
const Banner = lazy(() => import("../views/banner/Banner"));
const PostDetails = lazy(() => import("../views/post/PostDetails"));
const AddPost = lazy(() => import("../views/post/AddPost"));
const UpdatePost = lazy(() => import("../views/post/UpdatePost"));
const BankDetails = lazy(() => import('../views/bankDetails/BankDetails'));
const Payment = lazy(() => import('../views/payment/Payment'));
const PaymentDetails = lazy(() => import('../views/payment/PaymentDetails'));
const VerifyPayment = lazy(() => import('../views/payment/VerifyPayment'));
const PushNotification = lazy(() => import('../views/pushNotification/PushNotification'));
const PhonepePayment = lazy(() => import('../views/phonepePayment/Payment'));
const PhonepePaymentDetails = lazy(() => import('../views/phonepePayment/PaymentDetails'));
const Contact = lazy(() => import('../views/contact/Contact'));
const ContactDetails = lazy(() => import('../views/contact/Details'));
const ChatList = lazy(() => import('../views/chats/ChatList'));
const ChatDetails = lazy(() => import('../views/chats/ChatDetails'));
const ReportedUsers = lazy(() => import('../views/reportedUsers/ReportedUsers'));
const ReportUserDetails = lazy(() => import('../views/reportedUsers/ReportUserDetails'));

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}) => {
  if (!localStorage.getItem('token')) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const AllRoutes = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(getMyProfile());
    }
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login {...props} />} exact />
      <Route path="/" element={<ProtectedRoute><FullLayout /></ProtectedRoute>}>
        <Route path="" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } exact />
        <Route path="dropdown" element={
          <ProtectedRoute>
            <Dropdown />
          </ProtectedRoute>
        } exact />
        <Route path="user" element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        } exact />
        <Route path="new/user" element={
          <ProtectedRoute>
            <NewUser />
          </ProtectedRoute>
        } exact />
        <Route path="new/register/user" element={
          <ProtectedRoute>
            <NewRegistersUser />
          </ProtectedRoute>
        } exact />
        <Route path="candidates/rejected" element={
          <ProtectedRoute>
            <RejectedCandidates />
          </ProtectedRoute>
        } exact />
        <Route path="update/user/status/:id" element={
          <ProtectedRoute>
            <UpdateUserStatus />
          </ProtectedRoute>
        } exact />
        <Route path="admin" element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } exact />
        <Route path="contacts" element={
          <ProtectedRoute>
            <Contact />
          </ProtectedRoute>
        } exact />
        <Route path="contact/:id" element={
          <ProtectedRoute>
            <ContactDetails />
          </ProtectedRoute>
        } exact />
        <Route path="chats" element={
          <ProtectedRoute>
            <ChatList />
          </ProtectedRoute>
        } exact />
        <Route path="chat/:id" element={
          <ProtectedRoute>
            <ChatDetails />
          </ProtectedRoute>
        } exact />
        <Route path="reported/users" element={
          <ProtectedRoute>
            <ReportedUsers />
          </ProtectedRoute>
        } exact />
        <Route path="reported/user/:id" element={
          <ProtectedRoute>
            <ReportUserDetails />
          </ProtectedRoute>
        } exact />
        <Route path="user/details/:id" element={
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        } exact />
        <Route path="user/verify/:id" element={
          <ProtectedRoute>
            <UserVerify />
          </ProtectedRoute>
        } exact />
        <Route path="setting" element={
          <ProtectedRoute>
            <Setting />
          </ProtectedRoute>
        } exact />
        <Route path="post" element={
          <ProtectedRoute>
            <Post />
          </ProtectedRoute>
        } exact />
        <Route path="banner" element={
          <ProtectedRoute>
            <Banner />
          </ProtectedRoute>
        } exact />
        <Route path="post/details/:id" element={
          <ProtectedRoute>
            <PostDetails />
          </ProtectedRoute>
        } exact />
        <Route path="add/post" element={
          <ProtectedRoute>
            <AddPost />
          </ProtectedRoute>
        } exact />
        <Route path="update/post/:id" element={
          <ProtectedRoute>
            <UpdatePost />
          </ProtectedRoute>
        } exact />
        <Route path="bank/details" element={
          <ProtectedRoute>
            <BankDetails />
          </ProtectedRoute>
        } exact />
        <Route path="payment" element={
          <ProtectedRoute>
            <Payment />
          </ProtectedRoute>
        } exact />
        <Route path="payment/verify/:id" element={
          <ProtectedRoute>
            <VerifyPayment />
          </ProtectedRoute>
        } exact />
        <Route path="payment/details/:id" element={
          <ProtectedRoute>
            <PaymentDetails />
          </ProtectedRoute>
        } exact />
        <Route path="push/notification" element={
          <ProtectedRoute>
            <PushNotification />
          </ProtectedRoute>
        } exact />
        <Route path="phonepe/payment" element={
          <ProtectedRoute>
            <PhonepePayment />
          </ProtectedRoute>
        } exact />
        <Route path="phonepe/payment/details/:id" element={
          <ProtectedRoute>
            <PhonepePaymentDetails />
          </ProtectedRoute>
        } exact />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
};

export default AllRoutes
