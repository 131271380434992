import * as chatActions from './chat.actions';

export const chatFeatureKey = "chatInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    details: {}
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case chatActions.CHAT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case chatActions.CHAT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case chatActions.CHAT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case chatActions.CHAT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case chatActions.CHAT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case chatActions.CHAT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};