import axios from 'axios';
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_PHONEPE_PAYMENT_LIST_REQUEST = 'GET_PHONEPE_PAYMENT_LIST_REQUEST';
export const GET_PHONEPE_PAYMENT_LIST_SUCCESS = 'GET_PHONEPE_PAYMENT_LIST_SUCCESS';
export const GET_PHONEPE_PAYMENT_LIST_FAILURE = 'GET_PHONEPE_PAYMENT_LIST_FAILURE';

export const GET_PHONEPE_PAYMENT_DETAILS_REQUEST = 'GET_PHONEPE_PAYMENT_DETAILS_REQUEST';
export const GET_PHONEPE_PAYMENT_DETAILS_SUCCESS = 'GET_PHONEPE_PAYMENT_DETAILS_SUCCESS';
export const GET_PHONEPE_PAYMENT_DETAILS_FAILURE = 'GET_PHONEPE_PAYMENT_DETAILS_FAILURE';

export const CREATE_PHONEPE_PAYMENT_REQUEST = 'CREATE_PHONEPE_PAYMENT_REQUEST';
export const CREATE_PHONEPE_PAYMENT_SUCCESS = 'CREATE_PHONEPE_PAYMENT_SUCCESS';
export const CREATE_PHONEPE_PAYMENT_FAILURE = 'CREATE_PHONEPE_PAYMENT_FAILURE';

export const getPhonepePaymentList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PHONEPE_PAYMENT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/phonepe/payment/list/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PHONEPE_PAYMENT_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PHONEPE_PAYMENT_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getPhonepePaymentDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PHONEPE_PAYMENT_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/phonepe/payment/detail/${id}/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_PHONEPE_PAYMENT_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PHONEPE_PAYMENT_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createPhonepePaymentByAdmin = (data, setPaymentDetails, addPaymentToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_ADD_PAYMENT" });
            dispatch({ type: CREATE_PHONEPE_PAYMENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/phonepe/payment/add/by/admin/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_PHONEPE_PAYMENT_SUCCESS, payload: res.data });
            setPaymentDetails({
                planExpiryDate: ""
            });
            addPaymentToggle();
            toast("Expiry date added successfully");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PHONEPE_PAYMENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};