import axios from "axios";
import { toast } from "react-toastify";

const token = localStorage.getItem('token');

export const GET_VERSIONS_REQUEST = "GET_VERSIONS_REQUEST";
export const GET_VERSIONS_SUCCESS = "GET_VERSIONS_SUCCESS";
export const GET_VERSIONS_FAILURE = "GET_VERSIONS_FAILURE";

export const UPDATE_VERSIONS_REQUEST = "UPDATE_VERSIONS_REQUEST";
export const UPDATE_VERSIONS_SUCCESS = "UPDATE_VERSIONS_SUCCESS";
export const UPDATE_VERSIONS_FAILURE = "UPDATE_VERSIONS_FAILURE";

export const getVersions = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_VERSIONS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/app/version/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_VERSIONS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_VERSIONS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    };
};

export const updateVersions = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_UPDATE_VERSION" });
            dispatch({ type: UPDATE_VERSIONS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/update/app/version/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_VERSIONS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_VERSIONS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    };
};