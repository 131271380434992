import * as planActions from './phonepePlan.actions';

export const planFeatureKey = 'phonepePlanInfo';

const initialState = {
    loading: true,
    errorMsg: "",
    plans: [],
    isPlanStateChange: false
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case planActions.CREATE_PURCHASE_PLANS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.CREATE_PURCHASE_PLANS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true
            }

        case planActions.CREATE_PURCHASE_PLANS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.GET_PURCHASE_PLANS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.GET_PURCHASE_PLANS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                plans: payload.data,
            }

        case planActions.GET_PURCHASE_PLANS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case planActions.UPDATE_PURCHASE_PLANS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case planActions.UPDATE_PURCHASE_PLANS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                isPlanStateChange: true,
            }

        case planActions.UPDATE_PURCHASE_PLANS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "PLAN_STATE_CHANGE":
            return {
                ...state,
                isPlanStateChange: false
            }

        default: return state;
    }
};